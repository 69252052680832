.App {
  height: 100%;
}

.ant-modal-confirm-content {
  margin: 12px 0 !important;
}

.ant-row.ant-form-item {
  margin-bottom: 8px;
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-header-group-cell-label {
  justify-content: center;
}